.tooltipster-light {
	border-radius: 5px; 
	border: 1px solid #cccccc;
	background: #ededed;
	color: #666666;
}
.tooltipster-light .tooltipster-content {
	font-family: Arial, sans-serif;
	font-size: 14px;
	line-height: 16px;
	padding: 8px 10px;
}